const { innerWidth, innerHeight } = $(window)[0]
const bodyPadding = parseInt($('body').css('padding').split('px')[0])
let maxSize = (innerHeight - bodyPadding * 2) / 16
let width = maxSize * 9
let height = maxSize * 16

setTimeout(function () {
  window.scrollTo(0, 1);
}, 1000);

$('.slider, .slider-item, .slider-title, .slider-content').css({
	height,
	width,
})

$('.slider').slick({
	adaptiveHeight: true,
	infinite: false,
	vertical: true,
	lazyLoad: 'progressive',
	verticalSwiping: true,
}).slickAnimation();
